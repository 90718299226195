.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: -5;
  color: #424242;
  font-size: 0.875rem;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: tabular-nums;
  line-height: 1.25rem;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-bottom: 1px solid #979797;
}

.ant-select.ant-select-borderless.ant-select-single.ant-select-show-arrow.ant-select-open {
  border-bottom: 1px solid #4a87c0;
}

.ant-select-dropdown {
  border: 1px solid #a6c1dd;
  border-radius: 6px;
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #0255a3;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
  margin-right: -6px;
  padding-right: 15px;
}

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 0px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #f2f6fa;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #fff !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: #fff;
  border-color: #fff !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  width: 100%;
  left: 0;
  right: 0;
}

.countries-dropdown-container span{
  font-size: 15px;
  margin-top: 12px;
}

.countries-dropdown-container img{
  height: 19px;
  width: 19px;
  margin-right: 7px;
}

@media( max-width: 768px ) {
  .countries-dropdown-container span{
    font-size: 10.5px;
  }
  .countries-dropdown-container img{
    height: 16px;
    width: 16px;
  }
}