@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.ant-tabs-tab-btn {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #3877b0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #0255a3;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #0255a3;
  pointer-events: none;
  width: 0.125rem;
}

.ant-modal-title {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #0255a3;
}

.ant-tooltip-inner {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  padding: 12px 16px 12px 16px;
  border-radius: 2px;
}

.transferPassword>* .ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  text-align: right;
}

.ant-upload-list-item-info {
  margin-top: 19px;
  background: #fafcfd;
  border: 1px dashed #ccdded;
  border-radius: 2px;
  padding: 18px 16px 18px 16px;
}

.ant-upload-list-item-name {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0255a3;
}

.ant-input::placeholder,
.ant-input:placeholder-shown {
  color: #989EA6;
  opacity: 1
}

.ant-select-selection-placeholder {
  color: #989EA6;
}

@media (max-width: 768px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}