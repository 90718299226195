html,
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}

a {
  color: #4a86bd;
  text-decoration: none;
}

a:hover {
  color: #0255a3;
  ;
}

@media only screen and (min-width: 1024px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
  :root {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    font-size: 12px;
  }
}

/* Antd Popover css */
.ant-popover-arrow {
  display: none;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
}

.ant-picker-dropdown-range {
  padding: 0;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  display: none;
}

ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  padding: 4px 11px 4px;
  position: relative;
  align-items: center;
  display: none !important;
  background: #933e3e;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
  height: 0 !important;
}

.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  align-items: center;
  text-align: center;

  /* Brand/Brand-6 */

  color: #0255a3 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #0255a3 !important;
}
