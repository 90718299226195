.headerMain {
  width: 100%;
  box-shadow: 0px 6px 12px rgb(2 85 163 / 15%);
  z-index: 1;
  position: relative;
  background: #ffffff;
}

.headerWidthRestriction {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.mainHeader,
.mobileHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 5rem;
}

.mainHeader {
  justify-content: center;
}

.mobileHeader {
  align-items: center;
  justify-content: center;
}

.mobileNavBtn {
  position: absolute;
  right: 1.25rem;
  border: none;
}

.headerLinkSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  height: 5rem;
}

.resourceSection {
  border-bottom: 1px solid #c2d6e9;
  width: 100%;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.resourceWidthRestriction {
  width: 100%;
  max-width: 1200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.logo {
  margin-right: 0.625rem;
}

.linkBox {
  display: inline-block;
}

@media (max-width: 54.375rem) {
  .logo {
    width: 6.25rem;
    margin-right: 0rem;
  }

  .linkBox {
    display: block;
  }
}

.linksSmall,
.linksMedium,
.linksLarge,
.linksLargeActive,
.signedOutLinks {
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
}

.linksSmall:hover,
.linksMedium:hover,
.linksLarge:hover,
.linksLargeActive:hover,
.signedOutLinks:hover {
  color: #0255a3;
}

.linksSmall {
  font-size: 1rem;
  letter-spacing: 0.125rem;
  color: rgba(2, 85, 163, 0.7);
  margin-left: 1.25rem;
}

.linksMedium {
  width: 80.5rem;
  font-size: 1.125rem;
  letter-spacing: 0.01875rem;
  color: rgba(2, 85, 163, 0.7);
  margin-right: 0.625rem;
  margin-left: 1.25rem;
}

.linksLarge {
  width: 5rem;
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: 0.025rem;
  color: #3877b0;
  margin-left: 3.125rem;
}

.linksLarge:hover {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 20.5rem;
  color: #013b72;
}

.linksLargeActive {
  width: 5rem;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.025rem;
  padding-bottom: 1.5625rem;
  margin-left: 3.125rem;
  color: #0255a3;
  border-bottom: 0.125rem solid #989ea6;
}

.linksLargeActive:hover {
  color: #0255a3;
  border-bottom: 0.125rem solid #013b72;
}

.signedOutLinks {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-left: 1.25rem;
  justify-content: flex-end;
}

.authOptions {
  display: flex;
  margin-left: 2rem;
}


@media (max-width: 50rem) {
  .linkContainer {
    margin: 0.8125rem 0;
  }
}

@media (min-width: 768px) {
  .linkContainer {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1024px) { 
  .headerWidthRestriction {
    padding: 0 12px;
  }
}

.ant-popover-inner-content {
  padding: 0rem;
}

.ant-popover-inner {
  border-radius: 10.125rem;
}
